<template>
	<v-breadcrumbs divider=">" :items="breadcrumbs" />
</template>

<script>
export default {
	name: 'ProfileBreadcrumbs',
	data: function () {
		return {
			rootBreadcrumb: {
				disabled: true,
				text: this.$t('profile.breadcrumbs.settings')
			}
		}
	},
	computed: {
		breadcrumbs: function () {
			return [
				this.rootBreadcrumb,
				{
					text: this.$t(`profile.breadcrumbs.${this.$route.name}`)
				}
			]
		}
	}
}
</script>
